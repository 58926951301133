"use strict";

export default function (price) {
    if (price['toLocaleString'] && typeof price['toLocaleString'] === 'function') {
        return price.toLocaleString(_config.localString, {
            useGrouping: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return price;
}