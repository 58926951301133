// import "babel-polyfill"; /* polyfill for some ES2015 features for older Browsers eg. Promises on IE11 */
import 'popper.js';
import 'bootstrap';


if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

// let app = {
//     debug: false,
// };

let app = window.app || {};
window.app = app || {};
window.app.modules =  app.modules || {};

console.log("b2b------------------------------------");

import initModulesInScope from "./scripts/initModulesInScope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./scripts/loadScript').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
import { showCookieBar } from "./scripts/cookiePermissions";
showCookieBar();

//--------------- TODO: AUSSORTIEREN!!!!!!!

import * as allowCookieBtn from './scripts/allowCookieBtn';
app.modules.allowCookieBtn = allowCookieBtn.initInScope;

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as slickslider from './scripts/slickslider';
app.modules.slickslider = slickslider.initInScope;

import * as thumbSlider from './scripts/thumbSlider';
app.modules.thumbSlider = thumbSlider.initInScope;

import * as navbar from './scripts/navbar';
app.navbar = navbar.init;
app.modules.navbar = navbar.initInScope;

import * as updateTopicFilter from './scripts/updateTopicFilter';
app.modules.updateTopicFilter = updateTopicFilter.initInScope;

import * as panomax from './scripts/panomax';
app.modules.panomax = panomax.initInScope;

import * as stepForm from './scripts/stepForm';
app.modules.stepForm = stepForm.initInScope;

import * as toc from './scripts/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as actionChanger from './scripts/actionChanger';
app.modules.actionChanger = actionChanger.initInScope;

import * as isClickable from './scripts/isClickable';
app.modules.isClickable = isClickable.initInScope;


import * as affix from './scripts/affix';
app.modules.affix = affix.initInScope;

import * as affixBottom from './scripts/affixBottom';
app.modules.affixBottom = affixBottom.initInScope;

import * as selectTopics from './scripts/selectTopics';
app.modules.selectTopics = selectTopics.initInScope;

import * as parsley from './scripts/parsley';
app.modules.parsley = parsley.initInScope;

import * as ajaxForm from './scripts/ajaxForm';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as overlay from './scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as shariff from './scripts/shariff.js';
app.modules.shariff = shariff.initInScope;

import * as countdown from './scripts/countdown.js';
app.modules.countdown = countdown.initInScope;

import * as likeList from './scripts/likeList.js';
app.likeList = likeList.init;
app.modules.likeList = likeList.initInScope;

import * as shoppingList from './scripts/shoppingList.js';
app.shoppingList = shoppingList.init;
app.modules.shoppingList = shoppingList.initInScope;

import * as shoppingCart from './scripts/shoppingCart.js';
app.modules.shoppingCart = shoppingCart.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as showAllTeasers from './scripts/showAllTeasers';
app.modules.showAllTeasers = showAllTeasers.initInScope;

import * as userOverlay from './scripts/userOverlay';
app.userOverlay = userOverlay.init;

import * as cartOverlay from './scripts/cartOverlay';
app.cartOverlay = cartOverlay.init;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as clickNav from './scripts/click-nav';
app.modules.clickNav = clickNav.initInScope;

import * as datePicker from './scripts/datePicker';
app.modules.datePicker = datePicker.initInScope;

import * as rangeDatePicker from './scripts/rangeDatePicker';
app.modules.rangeDatePicker = rangeDatePicker.initInScope;

import * as animationStart from './scripts/animationStart';
app.modules.animationStart = animationStart.initInScope;

import * as lightbox from './scripts/lightbox';
app.modules.lightbox = lightbox.initInScope;

// import * as checkoutTracking from './scripts/checkoutTracking';
// app.modules.checkoutTracking = checkoutTracking.initInScope;

import * as inView from './scripts/inView';
app.modules.inView = inView.initInScope;

import * as fileUpload from './scripts/fileUpload';
app.modules.fileUpload = fileUpload.initInScope;

import * as numberSpinner from './scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as numberSpinnerGroup from './scripts/numberSpinnerGroup';
app.modules.numberSpinnerGroup = numberSpinnerGroup.initInScope;

import * as numberSpinnerGroupEnableButton from './scripts/numberSpinnerGroupEnableButton';
app.modules.numberSpinnerGroupEnableButton = numberSpinnerGroupEnableButton.initInScope;

import * as birthdayTimestamp from './scripts/birthdayTimestamp';
app.modules.birthdayTimestamp = birthdayTimestamp.initInScope;

import * as personalization from './scripts/personalization';
app.modules.personalization = personalization.initInScope;

import * as scrollTo from './scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope;

import * as waypoints from './scripts/waypoints';
app.modules.waypoints = waypoints.initInScope;

import * as darkpageOverlay from './scripts/darkpageOverlay';
app.modules.darkpageOverlay = darkpageOverlay.initInScope;

import * as darkpageOverlayWebcam from './scripts/darkpageOverlayWebcam';
app.modules.darkpageOverlayWebcam = darkpageOverlayWebcam.initInScope;

import * as person from './scripts/person';
app.modules.person = person.initInScope;

import * as radioTabs from './scripts/radioTabs';
app.modules.radioTabs = radioTabs.initInScope;

import * as keycard from './scripts/keycard';
app.modules.keycard = keycard.initInScope;

import * as jobFinder from './scripts/jobFinder';
app.modules.jobFinder = jobFinder.initInScope;

import * as collapseCheckbox from './scripts/collapseCheckbox';
app.modules.collapseCheckbox = collapseCheckbox.initInScope;

import * as voucherForm from './scripts/voucherForm';
app.modules.voucherForm = voucherForm.initInScope;

import * as loadNextStep from './scripts/loadNextStep';
app.modules.loadNextStep = loadNextStep.initInScope;

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as interestsFilter from './scripts/interestsFilter';
app.modules.interestsFilter = interestsFilter.initInScope;

import * as popupWindow from './scripts/popupWindow';
app.modules.popupWindow = popupWindow.initInScope;

import * as openPersonalizationModalByDefault from './scripts/openPersonalizationModalByDefault';
app.modules.openPersonalizationModalByDefault = openPersonalizationModalByDefault.initInScope;

//TODO prototype stuff for webshop - @Frontend Team please ignore for now
import * as paymentFormAjax from './scripts/btob/paymentFormAjax.js';
app.modules.paymentFormAjax = paymentFormAjax.initInScope;

import * as dropDown from './scripts/dropDown';
app.modules.dropDown = dropDown.initInScope;

import * as tooltip from './scripts/tooltip';
app.tooltip = tooltip.init;

app.modules.popover = function ($scope) {
    $scope.find('[data-toggle="popover"]').popover();
};

import * as animatedList from './scripts/animatedList';
app.modules.animatedList = animatedList.initInScope;

import * as googleMap from './scripts/googleMap';
app.modules.googleMap = googleMap.initInScope;

import * as showKeycardInput from './scripts/showKeycardInput';
app.modules.showKeycardInput = showKeycardInput.initInScope;

import * as shopAffiliateZermatt from './scripts/shopAffiliateZermatt';
app.modules.shopAffiliateZermatt = shopAffiliateZermatt.initInScope;

import * as notificationHeader from './scripts/notificationHeader';
app.modules.notificationHeader = notificationHeader.initInScope;

import * as wallsIo from './scripts/wallsIo';
app.modules.wallsIo = wallsIo.initInScope;

import * as toggleInputs from './scripts/toggleInputs';
app.modules.toggleInputs = toggleInputs.initInScope;

import trackingInit, {initInScope as trackingInitInScope} from "./scripts/tracking";
app.tracking = trackingInit;
app.modules.tracking = trackingInitInScope;

import * as qrScanner from './scripts/qrScanner';
app.modules.qrScanner = qrScanner.initInScope;

import * as iframeResizing from './scripts/iframeResizing';
app.modules.iframeResizing = iframeResizing.initInScope;

import * as iframeSizeBridge from './scripts/iframeSizeBridge';
app.modules.iframeSizeBridge = iframeSizeBridge.initInScope;


app.filterForm = function () {
    import('./scripts/filter-form/filter-form').then(function (filterForm) {
        app.modules.filterForm = filterForm.initInScope;

        filterForm.default();
        filterForm.initInScope($('body'));
    });

    import('./scripts/filter-form/quick-filter').then(function (quickFilter) {
        app.modules.filterFormquickFilter = quickFilter.initInScope;
        quickFilter.initInScope($('body'));
    });
};

import * as quickfinder from './scripts/quickfinder';
app.quickfinder = quickfinder.init();
app.modules.quickfinder = quickfinder.initInScope;

import * as sortable from './scripts/sortable';
app.modules.sortable = sortable.initInScope;

import * as loadShopTickets from './scripts/btob/load-tickets';
app.modules.loadShopTickets = loadShopTickets.initInScope;

import * as productForm from './scripts/btob/product-form';
app.modules.productForm = productForm.initInScope;

import * as cartDataForm from './scripts/btob/cart-data-form';
app.modules.cartDataForm = cartDataForm.initInScope;

import * as cartDataAutocomplete from './scripts/btob/cart-data-autocomplete';
app.modules.cartDataAutocomplete = cartDataAutocomplete.initInScope;

import * as orderSubmit from './scripts/btob/order-submit';
app.modules.orderSubmit = orderSubmit.initInScope;

import * as pricingCollection from './scripts/btob/pricing-collection';
app.modules.pricingCollection = pricingCollection.initInScope;

import * as conditionalForm from './scripts/conditionalForm';
app.modules.conditionalForm = conditionalForm.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})(jQuery);