"use strict";

import {ajaxClient} from './lib/ajaxClient.js';
import {isParsleyForm, isValid} from "../parsley";

// TODO refactor later - just copied from current matterhorn

export function initInScope ($scope) {
    const form = $scope.find('#js-paymentform-ajax');


    form.unbind().on('submit', function (event) {
        event.preventDefault();

        if (!isParsleyForm(form) || isValid(form)) {
            setLoading(true);
            ajaxClient.post(form.attr('data-action'), getSubmitData())
                .then(function (result) {
                    handleSubmitResult(result);
                })
                .fail(function (jqXHR) {
                    setLoading(false);
                });
        }
    });

    function getSubmitData(){
        let data = {};
        $.each(form.serializeArray(), function (index, obj) {
            data[obj.name] = obj.value;
        });

        return data;
    }
}

function handleSubmitResult(result){
    // redirect result is directly handled in AJAX client
    if ('undefined' !== typeof result.action && result.action === 'payment') {
            let config = result.payment.config;
            let priceDatatrans = result.payment.params.amount+'';
            let priceLastShown = _config.totalToPay+'';
            if ( priceDatatrans !== priceLastShown){
                // price is changed - redirect to payment page again
                console.error('[Payment]', 'paymentMismatch between ' + priceLastShown+ ' and ' + priceDatatrans);
                window.location.href = result.paymentMismatchUrl;
            }
            else {
                startPayment(config.script, config.action, result.payment.params);
            }
    }else {
         setLoading(false);

    }
}

function startPayment(script, action, attributes) {
    $.getScript(script).done(function () {
        Datatrans.startPayment({
            params: attributes,
            'opened': function () {
                setLoading(true);
            },
            'closed': function () {
                setLoading(false);
            }
        });
    });
}

function setLoading(state) {
    let $loading = $('.js-paymentform-ajax__loading');
    if (state) {
        $('body').addClass('loading');
        $loading.attr('hidden', false);
    } else {
        $('body').removeClass('loading');
        $loading.attr('hidden', 'hidden');
    }
}

