"use strict";

import "typeahead.js";
import Bloodhound from "typeahead.js"

export function initInScope ($scope) {
    let factory = init();

    $scope.find('.js-typeahead-personalization-history').each(function () {
        let $container = $(this);

        factory.initTypeahead($container);
        factory.handleSelectEvent($container, function (typeaheadObj, data) {
            $.each(['firstname', 'lastname', 'birthday'], function (idx, property) {
                let $input = $container.closest('.js-autocomplete').find('.js-autocomplete-' + property);
                $input.val($.trim(data[property]));
            });

            $container.val('');
        });
    });

    $scope.find('.js-typeahead-keycardnr').each(function () {
        let $container = $(this);
        
        factory.initTypeahead($container, {}, {
            display: function(data) {
                return data.number;
            },

            templates: {
                suggestion: function (data) {
                    return '<div>' + data.displayValue + '</div>';
                }
            }
        }, {
            identify: function (data) {
                return data.number;
            }
        });
    });
}

function init() {
    let defaultOptions = {
        hint: true,
        highlight: true,
        minLength: 1
    };

    let defaultDatasetOptions = {
        limit: Number.MAX_VALUE,
        display: 'displayValue'
    };

    return {
        initTypeahead: function ($container, options, datasetOptions, bloodhoundOptions) {
            if ($container.length === 0) {
                return;
            }

            let bloodhound = buildBloodhound($container.data('autocomplete-url'), bloodhoundOptions);

            let mergedOptions = $.extend(
                {},
                defaultOptions,
                options || {}
            );

            let mergedDatasetOptions = $.extend(
                {
                    source: bloodhound
                },
                defaultDatasetOptions,
                datasetOptions || {}
            );

            $container.typeahead(mergedOptions, mergedDatasetOptions);
        },

        handleSelectEvent: function ($container, handler) {
            if ('undefined' !== typeof handler) {
                $container.on('typeahead:select typeahead:autocomplete', handler);
            }
        }
    };
}

function buildBloodhound(url, options) {
    let defaultBloodhoundOptions = {
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            transform: function(data) {
                // Map the remote source JSON array to a JavaScript object array
                return data;
            }
        }
    };

    let glue = '?';
    if (url.indexOf('?') > -1) {
        glue = '&';
    }
    

    let mergedOptions = $.extend(
        true,
        {
            remote: {
                url: url + glue + 'term=%QUERY'
            }
        },
        defaultBloodhoundOptions,
        options || {}
    );
    

    return new Bloodhound(mergedOptions);
}