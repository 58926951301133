"use strict";

import initModulesInScope from "./initModulesInScope";

export function initInScope($scope) {
    let $container = $('.js-voucher-form__container');
    let $form = $container.find('.js-voucher-form');
    let $resultList = $container.find('.js-voucher-form__result-list');
    let $resultCart = $container.find('.js-voucher-form__result-cart');
    let $resultPaymentMethods = $container.find('.js-voucher-form__result-payment-methods');
    let $loading = $container.find('.js-voucher-form__loading');
    let pendingRequest = null;

    $('.js-voucher-form__submit').unbind().on('click', function (evt) {
        loadData(evt, 'submit');
    });

    $('.js-voucher-form__delete').unbind().on('click', function (evt) {
        let voucherId = $(this).closest('.js-voucher-form__item').attr('data-voucher-id') || console.warn("data-voucher-id not defined");
        loadData(evt, 'submit', null, true, voucherId);
    });

    $('.js-voucher-form__input').unbind().on('change', function(evt) {
        loadData(evt, 'change', $(this));
    });

    function loadData(evt, trigger, $inputToSerialize, deleteAction, voucherId) {

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        evt.preventDefault();

        $resultList.attr('hidden', null);
        $resultCart.attr('hidden', null);
        $loading.removeAttr('hidden');
        let data;



        let actionList = $form.attr('data-ajax-url-list');
        let actionCart = $form.attr('data-ajax-url-cart');
        let actionPaymentMethods = $form.attr('data-ajax-url-payment-methods');


        console.log("voucherpayment", actionPaymentMethods); 
        if(deleteAction) {
            data = {
                voucher: voucherId,
                action: 'delete'
            }
        } else {
            if(trigger == 'change') {
                data = $inputToSerialize.serialize();
            } else {
                data = $form.find('input').serializeArray();
            }
        }

        $.when(pendingRequest = $.ajax(actionList, {
                data: data,
                dataType: 'html'
            }
        )).then(function (resp1) {
            console.log("resp1");
            $resultList.empty().append(resp1);
             return  pendingRequest = $.ajax(
                actionCart, {
                    data: data,
                    dataType: 'html',
                }
            );
        }).then(function (resp2) {
            console.log("RESPONSE 3"); 
            $resultCart.empty().append(resp2);
            return  pendingRequest = $.ajax(
                actionPaymentMethods, {
                    data: data,
                    dataType: 'html',
                }
            );
        }).then(function (resp3) {
            console.log("resultpayments", resp3); 
            $resultPaymentMethods.empty().append(resp3);
        }).always(function() {
            $loading.attr('hidden', 'hidden');
            initModulesInScope($container);
            pendingRequest = null;
        });

    }

}