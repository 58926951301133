"use strict";

import initModulesInScope from "../initModulesInScope";

export function initInScope ($scope) {
    $scope.find('.js-load-tickets').on('click', function (evt) {
        evt.preventDefault();

        loadResults($(this).data('ajax-url'), $($(this).data('target')));
    });

    $scope.find('.js-load-tickets-form').each(function (index, form) {
        let $form = $(this);

        if($form.data('trigger')) {
            $form.on($form.data('trigger'), function (evt) {
                evt.preventDefault();

                if($form.data('collapse-target')){
                    $($form.data('collapse-target')).collapse('hide');
                }
                loadResults($(this).data('action'), $($(this).data('target')), $(this).serialize());
            });
        }else{
            $form.on('submit', function (evt) {
                evt.preventDefault();
                loadResults($(this).data('action'), $($(this).data('target')), $(this).serialize());
            });
        }
    });

}

function loadResults(action, $resultContainer, data) {
    let pendingRequest = null;

    if (pendingRequest) {
        pendingRequest.abort();
    }

    $resultContainer.collapse('show');
    $resultContainer.find('.js-load-tickets-loading').attr('hidden', false);

    pendingRequest = $.ajax({
        url: action,
        method: 'GET',
        data: data ? data : null
    }).done(function(data) {
        $resultContainer.find('.js-load-tickets-results').html(data);
        initModulesInScope($resultContainer.find('.js-load-tickets-results'));
    }).always(function () {
        pendingRequest = null;
        $resultContainer.find('.js-load-tickets-loading').attr('hidden', true);
    })
}