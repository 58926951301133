"use strict";

import initModulesInScope from "../initModulesInScope";
import formatPrice from './format-price';

export function initInScope ($scope) {
    $scope.find('.js-product-form').each(function () {
        let $form = $(this),
            $products = $form.find('.js-product-form__product');

        $form.on('submit', function (evt) {
            evt.preventDefault();

            submitForm($(this));
        });

        if($form.data('trigger')) {
            $form.on($form.data('trigger'), function () {
                submitForm($(this));
            });
        }

        $products.on('change', function () {
            let totalCount = 0,
                totalPrice = 0,
                totalBasePrice = 0,
                totalDiffPrice = 0;

            $products.each(function () {
                let $this = $(this),
                    $numberInput = $this.find('.js-number-spinner__input'),
                    count = +$numberInput.val(),
                    productPrice = null,
                    productBasePrice = null;

                if ($this.data('price-calculated')) {
                    let calculatedPrices = $this.data('price-calculated');
                    if ('undefined' !== typeof calculatedPrices[count]) {
                        productPrice = calculatedPrices[count];
                        productBasePrice = productPrice;
                    } else {
                        productPrice = 0;
                        productBasePrice = 0;
                    }
                } else {
                    let price = $this.data('price');
                    let basePrice = $this.data('baseprice');

                    productPrice = price * count;
                    productBasePrice = basePrice * count;
                }

                $this.find('.js-product-form__product__price').html(formatPrice(productPrice));

                totalCount += count;

                totalPrice += productPrice;
                totalBasePrice += productBasePrice;
                totalDiffPrice = totalBasePrice - totalPrice;
            });

            updateTotalPrice($form, totalPrice, totalDiffPrice, totalCount);
        });

        $form.find('.js-product-form__remove').on('click', function (evt) {
            evt.preventDefault();

            let pendingRequest = null;

            if (pendingRequest) {
                pendingRequest.abort();
            }

            pendingRequest = $.ajax({
                url: $(this).attr('href'),
                method: 'GET'
            }).done(function(data) {
                let $target = $($form.data('target'));
                $target.find('.js-product-form-loading').attr('hidden', false);
                updateSidebar($target);
            }).always(function () {
                pendingRequest = null;
            });
        });
    });
}

function updateTotalPrice($form, totalPrice, totalDiffPrice, totalCount) {
    let $totalPriceElement = $form.find('.js-product-form__price'),
        $totalBasePriceElement = $form.find('.js-product-form__baseprice'),
        $totalCountElement = $form.find('.js-product-form__count');

    $totalPriceElement.html(formatPrice(totalPrice));
    $totalBasePriceElement.html(formatPrice(totalDiffPrice));
    $totalCountElement.html(totalCount);

    if (totalDiffPrice <= 0) {
        $totalBasePriceElement.closest('.js-product-form__baseprice-container').attr('hidden', true);
    } else {
        $totalBasePriceElement.closest('.js-product-form__baseprice-container').attr('hidden', false);
    }
}

function updateSidebar(target) {
    if(!_config.cartSidebarUrl) {
        console.warn('_config.cartSidebarUrl is not defined');
        return;
    }

    let pendingRequest = null;

    if (pendingRequest) {
        pendingRequest.abort();
    }

    pendingRequest = $.ajax({
        url: _config.cartSidebarUrl,
        method: 'GET'
    }).done(function(data) {
        target.find('.js-product-form-sidebar').html(data);
        initModulesInScope(target);

        target.find('.js-product-form-loading').attr('hidden', true);
    }).always(function () {
        pendingRequest = null;
    });
}

function submitForm($form) {
    let pendingRequest = null;

    let url = $form.data('action'),
        data = $form.serialize(),
        $sidebar = $($form.data('target'));

    if (pendingRequest) {
        pendingRequest.abort();
    }

    $sidebar.find('.js-product-form-loading').attr('hidden', false);

    pendingRequest = $.ajax({
        url: url,
        data: data,
        method: 'POST',
        dataType: 'json'
    }).done(function(data) {
        if(data.success !== false) {
            updateSidebar($sidebar);
        }else{
            $sidebar.find('.js-product-form-loading').attr('hidden', true);
        }
    }).always(function () {
        pendingRequest = null;
    });
}