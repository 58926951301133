"use strict";

import formatPrice from './format-price';

export function initInScope ($scope) {
    let pricingCollections = $scope.find('.js-pricing-container-collection');
    if (pricingCollections.length === 0) {
        return;
    }

    let isValidValue = function(value) {
        return 'undefined' !== typeof value && null !== value;
    };

    let PricingContainer = function(container, parent) {
        this.container = container;
        this.parent    = parent;

        this.basePrice  = this.findBasePrice();
        this.totalPrice = this.basePrice;

        // performance - don't even bother to calculate stuff when we set container to static but stick
        // to total price derived from base price above
        if (container.hasClass('js-pricing-static')) {
            return;
        }

        // re-calculate on every variable change
        this.variables = container.find('.js-pricing-variable');
        this.variables.on('change', $.proxy(this.update, this));

        this.update(); // trigger initial state
    };

    /**
     * The base price defines the price of the container without any dynamic components. E.g. ticket price summary without
     * any deposit fees. Can either be set on a single elements (js-pricing-base) or on multiple js-pricing-base-components
     * which will be summed up to the final base price. The base price + the variable parts will be the total price of the
     * whole container.
     *
     * @returns {float}
     */
    PricingContainer.prototype.findBasePrice = function() {
        let basePriceElement = this.container.find('.js-pricing-base');
        if (basePriceElement.length === 1) {
            return basePriceElement.data('price');
        }

        let basePrice = 0;
        let basePriceComponents = this.container.find('.js-pricing-base-component');
        basePriceComponents.each(function() {
            basePrice += $(this).data('price');
        });

        return basePrice;
    };

    PricingContainer.prototype.update = function() {
        let totalPrice = this.basePrice;
        let results = this.getCategorizedResults();
        for (let category in results) {
            if (results.hasOwnProperty(category)) {
                if(results[category].price != 0) {
                    totalPrice += results[category].price;
                }
            }
        }

        this.totalPrice = totalPrice;
        this.updateView(results);

        // trigger event used by cart total price calculation
        this.parent.trigger('update.pricing-container');
    };

    PricingContainer.prototype.getCategorizedResults = function() {
        let self = this;
        let results = {};
        this.variables.each(function() {
            let variable = $(this);

            let configElement = variable;
            let active = false;

            if(variable.is(':disabled')){
                return;
            }

            if (variable.is('select')) {
                configElement = variable.find('option:selected');
            } else if (variable.is('input[type="checkbox"]')) {
                if (!variable.is(':checked')) {
                    return;
                }
            }

            let category = configElement.data('pricing-category');
            let price    = configElement.data('price');

            if (isValidValue(category) && category.length > 0) {
                if (isValidValue(price) && price !== 0) {
                    if ('undefined' === typeof results[category]) {
                        results[category] = {
                            count: 0,
                            price: 0
                        };
                    }

                    results[category].count++;
                    results[category].price += price;
                }
            }
        });

        return results;
    };

    PricingContainer.prototype.updateView = function(results) {
        let self = this;

        this.container.find('.js-pricing-result').each(function() {
            let resultContainer = $(this);
            let category = resultContainer.data('pricing-category');

            if (isValidValue(category) && category.length > 0) {
                if ('undefined' !== typeof results[category]) {
                    let categoryResult = results[category];

                    resultContainer.find('.js-pricing-count').html(categoryResult.count);
                    resultContainer.find('.js-pricing-price').html(formatPrice(categoryResult.price));

                    if (categoryResult.count > 0 && categoryResult.price != 0) {
                        resultContainer.attr('hidden', false);
                    } else {
                        resultContainer.attr('hidden', true);
                    }
                } else {
                    resultContainer.attr('hidden', true);
                }
            }

            // hide parent container if all subitems are hidden
            let resultCategory = resultContainer.closest('.js-pricing-result-category');
            if (resultCategory.length === 1) {
                self.updateResultCategoryVisibility(resultCategory);
            }
        });

        // hide empty pricing categories
        this.container.find('.js-pricing-result-category').each(function() {
            let categoryResults = $(this).find('.js-pricing-result');
            if (categoryResults.length === 0) {
                $(this).attr('hidden', true);
            }
        });
    };

    PricingContainer.prototype.updateResultCategoryVisibility = function(resultCategory) {
        let pricingResults = resultCategory.find('.js-pricing-result').not('[hidden]');
        resultCategory.attr('hidden', pricingResults.length === 0);
    };

    // normally we just have 1 collection per page
    pricingCollections.each(function(e) {
        let pricingCollection = $(this);
        let pricingContainers = [];

        pricingCollection.find('.js-pricing-container').each(function() {
            pricingContainers.push(new PricingContainer($(this), pricingCollection));
        });

        // iterate all pricing containers and summarize their total prices
        pricingCollection.on('update.pricing-container', function(e) {
            let totalPrice = 0;
            for (let i = 0; i < pricingContainers.length; i++) {
                totalPrice += pricingContainers[i].totalPrice;
            }

            pricingCollection.find('.js-pricing-grand-total .js-pricing-price').html(formatPrice(totalPrice));
        }).trigger('update.pricing-container'); // trigger initial state calculation
    });
}