"use strict";

import {isParsleyForm, isValid} from "../parsley";
import {ajaxClient} from './lib/ajaxClient.js';

export function initInScope ($scope) {
    let OrderSubmitHandler = function ($container) {
        this.$container = $container;

        this.loadCheckoutSteps();
        this.setupForm();
    };

    OrderSubmitHandler.prototype.loadCheckoutSteps = function () {
        let $this = this;

        this.steps = [];
        this.$container.find('.js-checkout__step').each(function () {
            let $container = $(this);
            let checkoutStep = $container.data('checkout-step');

            if (!checkoutStep) {
                checkoutStep = new CheckoutStep($container);
                $container.data('checkout-step', checkoutStep);
            }

            $this.steps.push(checkoutStep);
        });

    };

    OrderSubmitHandler.prototype.setupForm = function () {
        let $this = this;

        // this.$form = this.$container.find('.checkout-container__order-submit form');
        this.$form = this.$container.find('.js-checkout__submit-form');
        this.$form.on('submit', function (e) {
            e.preventDefault();
            $this.submit();
        });
    };

    OrderSubmitHandler.prototype.getSubmitData = function () {
        let submitData = {
            'steps': {}
        };

        $.each(this.steps, function (idx, step) {
            submitData.steps[step.name] = step.getSubmitData();
        });

        return submitData;
    };

    OrderSubmitHandler.prototype.submit = function () {
        let that = this;

        let handleSubmit = function () {
            ajaxClient.post(that.$form.attr('action'), that.getSubmitData())
                .then(function (result) {
                    console.log('[OrderSubmitHandler]', 'Result', result);
                    //todo b2b.logger ?
                    // matterhorn.B2B.Logger.log('[OrderSubmitHandler]', 'Result', result);
                    that.handleSubmitResult(result);
                })
                .fail(function (jqXHR) {
                    console.error('[OrderSubmitHandler]', 'Failed', jqXHR);
                    //todo b2b.logger ?
                    // matterhorn.B2B.Logger.error('[OrderSubmitHandler]', 'Failed', jqXHR);
                    that.setLoading(false);
                });
        };

        this.setLoading(true);

        let valid = true;
        for (let i = this.steps.length - 1; i >= 0; i--) {
            if (isParsleyForm(this.steps[i].$form) && !isValid(this.steps[i].$form)) {
                valid = false;
            }
        }

        if(valid) {
            this.submitCartDataForms().then(function() {
                console.info('[OrderSubmitHandler]', 'Submitted cart data forms');
                //todo b2b.logger ?
                // matterhorn.B2B.Logger.info('[OrderSubmitHandler]', 'Submitted cart data forms');
                handleSubmit();
            }).fail(function() {
                console.error('[OrderSubmitHandler]', 'Failed to submit cart data forms');
                //todo b2b.logger ?
                // matterhorn.B2B.Logger.error('[OrderSubmitHandler]', 'Failed to submit cart data forms');
                that.setLoading(false);
            });
        }
    };

    OrderSubmitHandler.prototype.submitCartDataForms = function () {
        let deferreds = [];
        let funcQueue = [];

        this.$container.find('.js-cart-data-form').each(function(){
                let productDataForm = $(this).data('product-data-form');

                let needsSubmit = !productDataForm.isValid() || (productDataForm.isValid() && productDataForm.isFormOpen());
                if (needsSubmit) {
                    let callForm = function () {
                        let deferred = $.Deferred();
                        productDataForm.openForm();
                        productDataForm.saveForm().done(function () {
                            deferred.resolve();
                        }).fail(function () {
                            deferred.reject();
                        });
                        return deferred;
                    };
                    funcQueue.push(callForm);
                }
            }
        );

        let $deferred;
        funcQueue.forEach(
            function (entry) {
                if(!$deferred){
                    $deferred = entry();
                }else {
                    $deferred = $deferred.then(entry);
                }
                deferreds.push($deferred);
            }
        );

        return $.when.apply(this, deferreds);
    };

    OrderSubmitHandler.prototype.handleSubmitResult = function (result) {
        // redirect result is directly handled in AJAX client
        if ('undefined' !== typeof result.action ) {
            if (result.action === 'payment') {
                let config = result.payment.config;
                this.startPayment(config.script, config.action, result.payment.params);
            }
        }
    };

    OrderSubmitHandler.prototype.startPayment = function (script, action, attributes) {
        let that = this;

        $.getScript(script).done(function () {
            Datatrans.startPayment({
                params: attributes,
                'opened': function () {
                    that.setLoading(true);
                },
                'closed': function () {
                    that.setLoading(false);
                }
            });
        });
    };

    OrderSubmitHandler.prototype.setLoading = function (state) {
        if (state) {
            $('body').trigger('startLoading');
        } else {
            $('body').trigger('stopLoading');
        }
    };

    let CheckoutStep = function ($container) {
        this.$container = $container;
        this.name = this.$container.data('step');

        this.setupForm();
    };

    CheckoutStep.prototype.setupForm = function () {
        this.$form = this.$container.find('.js-checkout__step__form');
        this.$form.on('submit', function (e) {
            // form is not meant to be submitted directly
            e.preventDefault();
        });
    };

    CheckoutStep.prototype.getSubmitData = function () {
        let data = {};
        if (this.$form.length === 0) {
            return data;
        }

        // does not support complex structures (e.g. arrays) -> use something
        // like jquery.serializeJSON if needed
        $.each(this.$form.serializeArray(), function (index, obj) {
            data[obj.name] = obj.value;
        });

        return data;
    };

    let $checkoutContainer = $scope.find('.js-checkout');
    if ($checkoutContainer.data('order-submit-handler')) {
        return;
    }

    let orderSubmitHandler = new OrderSubmitHandler($checkoutContainer);
    $checkoutContainer.data('order-submit-handler', orderSubmitHandler);
}