
// TODO refactor later - just copied from current matterhorn
let client = {
    ajax: function (url, settings) {
        return $.ajax(url, settings)
            .then(this.successHandler)
            .fail(this.errorHandler);
    },

    successHandler: function (response) {

        let deferred = $.Deferred();
        if (!handleRedirect(response)) {
            deferred.resolve(response);
        }

        return deferred.promise();
    },

    errorHandler: function (jqXHR, textStatus, errorThrown) {
        // console.error('[AjaxClient]', 'Error', jqXHR, textStatus, errorThrown);

        let deferred = $.Deferred();
        if (!handleRedirect(jqXHR.responseJSON)) {
            deferred.reject(jqXHR, textStatus, errorThrown);
        }

        return deferred.promise();
    }
};

let shorthand = function (type) {
    return function (url, data, success, dataType) {
        return client.ajax(url, {
            type: type,
            url: url,
            data: data,
            success: success,
            dataType: dataType
        });
    }
};

// setup get and post shorthand methods
client.get = shorthand('GET');
client.post = shorthand('POST');

function handleRedirect(json) {
    if (!('object' === typeof json)) {
        return false;
    }

    if ('undefined' !== typeof json.action && json.action === 'redirect') {
        if ('undefined' !== typeof json.location) {
            // console.info('[AjaxClient]', 'Redirecting to', json.location);
            window.location.href = json.location;

            return true;
        }
    }

    return false;
}


export {client as ajaxClient};
