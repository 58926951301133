"use strict";

import {isParsleyForm, isValid} from "../parsley";
import {ajaxClient} from './lib/ajaxClient.js';

export function initInScope ($scope) {
    let ProductDataForm = function ($element) {
        let $this = this;
        this.$element = $element;
        this.validClass = 'is-valid';

        /* form */
        this.$form = $element.find('.js-cart-data-form__form');
        this.$formInputs = this.$form.find(':input.js-cart-data-form__input');

        /* static View */
        this.$staticView = this.$element.find('.js-cart-data-form__static');
        this.$staticViewData = this.$staticView.find('.js-cart-data-form__static__data');

        /* event bindings */
        this.$element.on('startLoading', function (e) {
            $this.setLoading(true);
            e.stopPropagation();
        });

        this.$element.on('stopLoading', function (e) {
            $this.setLoading(false);
            e.stopPropagation();
        });

        this.$element.find('.js-cart-data-form__submit').on('click', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation();

            $this.saveForm();
        });

        this.$element.find('.js-cart-data-form__remove').on('click', function (evt) {
            evt.preventDefault();

            ajaxClient.post($(this).attr('href')).then(function () {
                window.location.reload();
            });
        });

        this.$element.on('show.bs.collapse', function () {
            $this.$element.addClass('is-open');
        });

        this.$element.on('hide.bs.collapse', function () {
            $this.$element.removeClass('is-open');
        });
    };

    /**
     * Set loading state
     *
     * @param {boolean} state
     */
    ProductDataForm.prototype.setLoading = function (state) {
        let $loading = this.$element.find('.js-cart-data-form__loading');

        // disable/enable all submit Buttons ! -> parallel saves would overwrite each other
        $('.js-pricing-container-collection').find('.js-cart-data-form__submit').prop('disabled', state);

        if (state) {
            $loading.attr('hidden', false);
        } else {
            $loading.attr('hidden', true);
        }
    };

    /**
     * Update static placeholders with form element data. Placeholders define a data-static-placeholder
     * property which is used to find the input to use as data source.
     */
    ProductDataForm.prototype.updateStaticView = function () {
        let $this = this;
        this.$element.find('[data-static-placeholder]').each(function () {
            let $placeholder = $(this);
            let inputName = $placeholder.data('static-placeholder');
            let value = '';

            let $input = $this.$element.find(':input[name="' + inputName + '"]');
            if ($input.length === 1) {
                // if ($input.is('[data-date-picker-pair-target]')) {
                //     let pair = $input.data('date-picker-pair');
                //     value = pair.formatDate();
                // } else {
                    value = $input.val();
                // }
            }

            $placeholder.html(value);
        });
    };

    /**
     * Save form to backend action and add form errors to individual fields. Intentionally submits to backend even if
     * frontend validation fails to get more detailed error messages and to save partially valid data.
     *
     * @return {jQuery AJAX promise}
     */
    ProductDataForm.prototype.saveForm = function () {
        let $this = this;

        //close popovers
        $($this.$form).find('[data-toggle="popover"]').popover('hide');

        if (isParsleyForm(this.$form) && !isValid(this.$form)) {
            return;
        }

        this.setLoading(true);
        this.$element.removeClass(this.validClass); // remove valid class -> will be re-added on success

        let url = this.$form.attr('action'),
            method = this.$form.attr('method'),
            options = this.$formInputs.serialize();

        return ajaxClient.ajax({
            type: method,
            url: url,
            data: options,
            success: function (data) {
                $this.clearFormErrors();
                $this.updateStaticView();

                $this.$element.find('.collapse').collapse('hide');
                $this.$element.addClass($this.validClass);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                $this.setLoading(false);

                // set default error message on invalid fields
                if (!(jqXHR.status === 400 && 'undefined' !== typeof jqXHR.responseJSON && 'undefined' !== typeof jqXHR.responseJSON.errors)) {
                    return;
                }

                $.each(jqXHR.responseJSON.errors, function (errorIdx, error) {
                    // set backend errors
                    $.each(error.fields, function (fieldIdx, field) {
                        let input = $this.$formInputs.filter('[name="' + field + '"]');
                        if (input.length === 1) {
                            let inputParsley = input.parsley();

                            // add backend message
                            inputParsley.removeError('backend');
                            inputParsley.addError('backend', {message: error.errorMessage});

                            inputParsley.removeError('required');

                            input
                                .removeClass('parsley-success')
                                .addClass('parsley-error');
                        }
                    });
                });

                $this.addFormErrors(jqXHR.responseJSON.errors);
            },

            complete: function (jqXHR, textStatus) {
                $this.setLoading(false);
            }
        });
    };

    /**
     * Load error container
     *
     * @returns {jQuery Object}
     */
    ProductDataForm.prototype.getErrorContainer = function () {
        return this.$element.find('.js-cart-data-form__errors');
    };

    /**
     * Clear form errors
     */
    ProductDataForm.prototype.clearFormErrors = function () {
        let $errorContainer = this.getErrorContainer();

        $errorContainer.find('ul li').remove();
        $errorContainer.attr('hidden', true);
    };

    /**
     * @param {Object} errors
     */
    ProductDataForm.prototype.addFormErrors = function (errors) {
        let $this = this;

        this.clearFormErrors();

        let $errorContainer = this.getErrorContainer();
        if ($errorContainer.length === 0) {
            return;
        }

        let $errorList = $errorContainer.find('ul');

        $.each(errors, function(errorIdx, error) {
            $.each(error.fields, function(fieldIdx, field) {
                let $input = $this.$formInputs.filter('[name="' + field + '"]');
                if($input.is(':visible')){
                    let $label = $input.closest('.form-group').find('label').first();

                    let title = field;
                    if ($label.length === 1) {
                        title = $label.text();
                    }

                    $('<li />')
                        .append($('<strong />').html(title + ':'))
                        .append(' ')
                        .append(error.errorMessage)
                        .appendTo($errorList);
                }
            });
        });

        $errorContainer.attr('hidden', false);
    };

    /**
     * @returns {boolean}
     */
    ProductDataForm.prototype.isValid = function () {
        return this.$element.hasClass(this.validClass);
    };

    /**
     * @returns {boolean}
     */
    ProductDataForm.prototype.isFormOpen = function () {
        return this.$element.hasClass('show');
    };

    ProductDataForm.prototype.openForm = function () {
        if(this.$element.parents('.collapse')) {
            this.$element.parents('.collapse').collapse('show')
        }
        this.$element.collapse('show');
    };

    ProductDataForm.prototype.closeForm = function () {
        this.$element.collapse('hide');
    };

    $scope.find('.js-cart-data-form').each(function () {
        let $form = $(this);
        if (!$form.data('product-data-form')) {
            let productDataForm = new ProductDataForm($form);
            $form.data('product-data-form', productDataForm);
        }
    });
}